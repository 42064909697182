<template>
    <div>

        <b-modal
            id="add_client_addpayment"
            ref="madd_client_addpayment"
            :title="$parent.lang.add_client_voucher"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.add_client_voucher }}
                    </div>
                </div>
            </template>
            <!-- <v-row class="text-center p-3 m-3" :style="`font-size:1.2em;background:red;border:1px solid #000;color:#FFF;direction:`+$parent.$parent.lang.dir">
               المبلغ المتبقي على العميل: {{$convertToComa(cAmount,$store.state.licenseType.moneyComma)}}
            </v-row> -->
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" class="p-1 backRed">
                        {{ $parent.lang.total_payment_to_Client }} {{ remain_total }}
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label for="input-live">{{$parent.lang.mobile}}</label>
                        <b-form-input class="inborder" v-model="mobile" @change="getClient" />
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <label for="input-live">{{$parent.lang.customer}}</label>
                        <b-form-input class="inborder" v-model="customer_name" disabled  />
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <label for="input-live">{{$parent.lang.date}}</label>
                                    <b-form-input
                                      v-model="date"
                                      trim
                                      prepend-icon="mdi-calendar"
                                      :rules="fieldRules"
                                      readonly
                                      class="inborder"
                                      v-on="on"
                                      v-bind="attrs"
                                    >{{ date }}</b-form-input>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    
                    <v-col cols="12" md="6" sm="12">
                        <label for="input-live">{{$parent.lang.received_amount}}</label>
                            <b-form-input
                                v-model="received_total"
                                trim                                
                                class="inborder"
                            >{{ remaintotal }}</b-form-input>
                    </v-col>
                    
                    <v-col cols="12" md="6" sm="12">
                        <label for="input-live">{{$parent.lang.payment_method}}</label>
                        <b-form-select class="selborder" v-model="paytype" :options="payTypeOp">
                              </b-form-select>
                    </v-col>
                    
                    <v-col cols="12" md="12" sm="12">
                        <label for="input-live">{{$parent.lang.description}}</label>
                        <v-textarea
                            v-model="notes"
                            style="height:150px !important;"
                            class="inborder"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </div>

            <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addVoucher()' id="adClientVouBTN" style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                    <b-button type="button" @click='close()' id="payClientHide" style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
        
    </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';

export default{
    data() {
        return {
            customerid: 0,
            remain_total: 0,
            inv_total: 0,
            paid_total: 0,
            id: 0,
            customer_name: '',
            cAmount: '',
            vendorid: '',
            vendorsAll: [],
            received_total:'',
            fieldRules:[
                v => !!v || 'هذا الحقل مطلوب'
            ],
            cashing: false,
            spaning: true,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            mobile: '',
            full_name:'',
            
            notes:'',
            paytype:1,
            paycash:'',
            payspan:'',
            payTypeOp:[],
            cardid: 0
        }
    },
    computed: {
        remaintotal: function () {
            return this.cAmount;
        },
        paymentOfVendor: function(){
            let t =0;
            for(let i=0;i<this.vendorsAll.length;i++){
                if(this.vendorid == this.vendorsAll[i].value){
                    t = this.vendorsAll[i].total;
                }
            }
            return this.$RoundNums(t,2);
        }
    },
    created() {
        // this.received_total = this.cAmount;
        // this.getAllVendors();
        setTimeout(() => {this.getPyamentType()},2000);
    },
    methods: {
        getClient(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getClientTotal');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[mobile]',this.mobile);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data.results.data;
                this.mobile =res.mobile;
                this.customer_name =res.customer_name;
                this.customerid = res.customerid;
                this.inv_total = res.inv_total;
                this.paid_total = res.paid_total;
                this.remain_total = res.remain_total;
            })
        },
        getAllVendors(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getVendors');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.vendorsAll = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.vendorsAll.push({
                        text: res.results.data[i].full_name ,
                        value: res.results.data[i].vendorid,
                        total: res.results.data[i].payment_total
                    })
                }
                // this.paytypeOp = t;

                this.vendorid = res.results.data[0].vendorid;
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.payTypeOp.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.paytype = res.results.data[0].id;
            })
        },
        PaymentDo() {
            if(this.paytype == 1){
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                let pc = this.paycash;
                let ps = this.payspan;
                if(pc != 0){
                    ps = parseFloat(this.received_total) - parseFloat(pc);
                }else if(ps != 0){
                    pc = parseFloat(this.received_total) - parseFloat(ps);
                }
                this.paycash = pc;
                this.payspan = ps;
            }
        },
        checkPay(){
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = 0;
                this.paycash = this.received_total;
            }
        },
        doPaytype(){
            if(this.received_total > this.remaintotal){
                this.received_total = this.remaintotal;
            }
            if(this.paytype == 1){
                this.cashing = false;
                this.spaning = true;
                this.paycash = this.received_total;
                this.payspan = 0;
            }else if(this.paytype == 2){
                this.cashing = true;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }else if(this.paytype == 3){
                this.cashing = false;
                this.spaning = false;
                this.payspan = this.received_total;
                this.paycash = 0;
            }
           
        },
        addVoucher(){
            document.getElementById('adClientVouBTN').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type",'addClientVouchter');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[mobile]',this.mobile)
            post.append('data[customer_name]',this.customer_name)
            post.append('data[customerid]',this.customerid)
            post.append('data[payspan]',0)
            post.append('data[paycash]',0)

            post.append('data[type]',1);
            post.append('data[amount]',this.received_total)
            post.append('data[paytype]',this.paytype)
            post.append('data[notes]',this.notes)
            post.append('data[date]',this.date)

            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    // // console.log("paymentgo",res);
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    this.modal = false;
                    this.mobile = '';
                    this.full_name ='';
                    this.received_total ='';
                    this.notes = '';
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    let message = "تمت الاضافة بنجاح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    // this.$parent.getCard(this.id);
                    this.getClient();
                    document.getElementById('payClientHide').click();
                    document.getElementById('adClientVouBTN').disabled = false;
                }
            )
        }
    },
}
</script>