<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
        <b-modal
            id="add_product"
            ref="madd_productodal"
            :title="$parent.lang.add_product"
            size="lg"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ $parent.lang.add_product }}
                    </div>
                </div>
            </template>
                <v-row style="direction:rtl;margin:5px;" class="m-2">
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.item_code}}</label>
                        <b-form-input class="inborder"
                            v-model="product.item_code"
                            @change="getItem()"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="5" sm="12">
                        <label>{{lang.namear}}</label>
                        <b-form-input class="inborder"
                            v-model="product.name"
                            ></b-form-input>
                    </v-col>
                  <v-col cols="12" md="5" sm="12">
                    <label>{{lang.nameen}}</label>
                    <b-form-input class="inborder"
                        v-model="product.nameen"
                    ></b-form-input>
                  </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.current_qtty}}</label>
                        <b-form-input class="inborder"
                            v-model="product.qty"
                            
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.price_without_vat}}</label>
                        <b-form-input class="inborder"
                            v-model="product.price"
                            
                            @change="CalckValues(1)"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.price_inc_vat}}</label>
                        <b-form-input class="inborder"
                            v-model="product.taxprice"
                            
                            @change="CalckValues(2)"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.default_cost}}</label>
                        <b-form-input class="inborder"
                            v-model="product.cost"
                            
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.product_category}}</label>
                        <b-input-group>
                            <b-form-select class="selborder" 
                            style="width:200px !important" 
                            v-model="product.catid" 
                            :options="productcats"
                            @change="doconsole()"
                            ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down" style="z-index:"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="3" sm="12">
                        <label>{{lang.product_type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder" 
                            style="width:200px !important" 
                            v-model="product.product_type" 
                            :options="productstype"
                            @change="doconsole()"
                            ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down" style="z-index:"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{ lang.added_value_tax }}</label>
                        <v-switch
                            v-model="product.isvat"
                            color="#ff0000"
                            @change="changeTax()"
                        ></v-switch>
                    </v-col>
<!--                    <v-col cols="12" md="6" sm="12">-->
<!--                        <v-switch-->
<!--                            v-model="product.issmoke"-->
<!--                            label="ضريبة الدخان"-->
<!--                            @change="changeTax()"-->
<!--                        ></v-switch>-->
<!--                    </v-col>-->
                </v-row>
                
                <v-row style="direction:rtl;margin:5px;">
                    <h5>{{ lang.smiller_products }}</h5>
                    <v-col cols="12" md="2" sm="12" v-for="(item,index) in smiller_items" :key="index">
                        <label>{{lang.item_code}}</label>
                        <b-form-input class="inborder"
                            v-model="smiller_items[index].itemcode"
                            @change="CgetItem(item,index)"
                            ></b-form-input>
                    </v-col>
                </v-row>
                <v-row v-if="$store.state.licenseType.otherproducts">
                    <v-col cols="12" md="12" sm="12" style="direction:rtl;margin:5px;">
                        <b-table-simple>
                            <thead>
                                <tr>
                                    <th colspan="5">بدائل المنتج</th>
                                </tr>
                                <tr>
                                    <th>رقم الصنف</th>
                                    <th>اسم الصنف</th>
                                    <th>سعر البيع</th>
                                    <th>التكلفة الافتراضية</th>
                                    <th>الكمية المتوفرة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in alternatives" :key="index">
                                    <td>{{item.item_code}}</td>
                                    <td>{{item.name}}</td>
                                    <td>{{item.price}}</td>
                                    <td>{{item.cost}}</td>
                                    <td>{{item.qty}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>رقم الصنف البديل</th>
                                    <th colspan="2">
                                        <v-text-field
                                            label="رقم الصنف"
                                            v-model="item_code"
                                            @change="getAlternative()"
                                            >{{ item_code }}</v-text-field>
                                    </th>
                                    <th colspan="2">

                                    </th>
                                </tr>
                            </tfoot>
                        </b-table-simple>
                    </v-col>
                </v-row>
                <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addProd()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                    <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
            <vue-snotify></vue-snotify>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            smiller_items: [
                {itemcode: ''},
            ],
            taxes: [
                {
                    'id' : 1,
                    'namear': 'ضريبة القيمة المضافة',
                    'percentage': 15,
                },
                // {
                //     'id' : 2,
                //     'namear': 'ضريبة التدخين',
                //     'percentage': 100,
                // }
            ],
            item_code: '',
            productsname: [],
            product: {
                item_code: '',
                name: '',
                nameen: '',
                qty: 1,
                price: '',
                taxprice: 0,
                type: 1,
                cost: '',
                catid: 0,
                isvat: true,
                issmoke: false,
                taxs: '1',
                product_type: ''
            },
            productcats: [], 
            alternatives: [],
            deftype: 1
        }
    },
    methods: {
        doconsole() {
            // console.log("holaaaa",this.product);
        },
        changeTax(){
            let tax = '';
            if(this.product.isvat === true){
                tax = '1'
            }
            if(this.product.issmoke === true){
                // if(tax == '1'){
                //     tax = '1,2'
                //     tax = '1,2'
                // }else{
                //     tax = '2';
                // }
            }
            this.product.taxs = tax;
            this.CalckValues(1);
        },
        CalckValues(i){
            // console.log(this.product);
            if(i==1){
                let vat = 0;
                let smoke = 0;
                if(this.product.isvat === true){
                    let t = this.$calcVat(this.product.price,2);
                    vat = t.vat;
                }
                // if(this.product.issmoke == true){
                //     smoke = this.product.price;
                // }
                this.product.taxprice = parseFloat(this.product.price) + parseFloat(vat) + parseFloat(smoke);
                
            }else{
                let value = 100;
                let vat = 0;
                let smoke = 0;
                if(this.product.isvat == true){
                    value = parseFloat(value) + 15;
                }
                // if(this.product.issmoke == true){
                //     value = parseFloat(value) + 100;
                // }
                const price = this.$calcTax(this.product.taxprice,value)
                
                this.product.price = price;
            }
        },
        getCats(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getPCatsList");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',"all0");
            post.append('data[lang]',"ar");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((res) => {
                const p = res.data;
                let t = [];
                if(typeof p.results !== 'undefined' && typeof p.results.data !== 'undefined'){
                    if(p.results.data.length != 0){
                        for(let i=0;i<p.results.data.length;i++){
                            t.push({
                                text: p.results.data[i].namear,
                                value: p.results.data[i].id
                            });
                            // if(i == 0){
                                this.product.catid = p.results.data[i].id
                                this.deftype = p.results.data[i].id
                            // }
                        }
                    }
                }
                // console.log(this.deftype);
                this.productcats = t;
            });
        },
        resetAll(){
            
            this.item_code = '';
            this.productsname = [];
            this.product.item_code = '';
            this.product.name = '';
            this.product.nameen = '';
            this.product.qty = 1;
            this.product.price = 0;
            this.product.taxprice = 0;
            this.product.type = 1;
            this.product.product_type = 1;
            this.product.cost = 0;0
            this.product.true = true;
            this.product.issmoke = false;
            this.product.taxes = '1';
            this.product.catid = this.deftype;
            // this.productcats = [];
            this.alternatives = [];
            this.smiller_items = [{itemcode: ''}]
        },
        getItem() {
            // console.log("I am resting");
            const itemcode = this.product.item_code;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getItemBycode");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[itemcode]",itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.results.length > 0){
                    this.product.item_code = res.results.data.results[0].itemcode;
                    this.product.name = res.results.data.results[0].name;
                    this.product.nameen = res.results.data.results[0].nameen;
                    this.product.qty = res.results.data.results[0].qty;
                    this.product.price = res.results.data.results[0].price;
                    this.product.cost = res.results.data.results[0].cost;
                    this.product.catid = res.results.data.results[0].catid;
                    this.product.type = res.results.data.results[0].type;
                    this.alternatives = res.results.data.results[0].alternative;
                    this.product.product_type = res.results.data.results[0].product_type;
                    // console.log(this.product.smill);
                    for(let i=0; i< res.results.data.results[0].smill.length;i++ ){
                        this.smiller_items.push({
                            itemcode: res.results.data.results[0].smill[i]
                        })
                    }
                }
            });
        },
        CgetItem(item,index) {
            // console.log("I am resting");
            const itemcode = this.product.item_code;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getItemBycode");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[itemcode]",item.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.results.length > 0){
                    this.smiller_items[index].itemcode = '';
                }else{
                    this.smiller_items.push({itemcode: ''})
                }
            });
        },
        getAlternative(){
            const itemcode = this.item_code;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getItemBycode");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[itemcode]",itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.alternatives.push({
                    item_code: res.results.data.results[0].itemcode,
                    name: res.results.data.results[0].name,
                    price: res.results.data.results[0].price,
                    qty: res.results.data.results[0].qty,
                    cost: res.results.data.results[0].cost,
                });
                this.item_code = '';
            });
        },
        getProducts(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getallproducts");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]","1");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                if(res.results.data.length > 0){
                    for(let i=0;i<res.results.data.length;i++){
                        this.productsname.push(res.results.data[i])
                    }
                }
            });
        },
        addProd() {
            if(this.product.nameen == '' && this.product.name == ''){
                let message = "يرجى اضافة الاسم العربي للصنف";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                return false;
            }
            else if(this.product.nameen != '' && this.product.name == ''){
                this.product.name = this.product.nameen
            }
            else if(this.product.name != '' && this.product.nameen == ''){
                this.product.nameen = this.product.name
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "addNewProduct");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[item_code]",this.product.item_code);
            
            post.append("data[item_code]",this.product.item_code);
            post.append("data[name]",this.product.name);
            post.append("data[nameen]",this.product.nameen);
            post.append("data[qty]",this.product.qty);
            post.append("data[price]",this.product.price);
            post.append("data[taxprice]",this.product.taxprice);
            post.append("data[type]",this.product.type);
            post.append("data[cost]",this.product.cost);
            post.append("data[catid]",this.product.catid);
            post.append("data[isvat]",this.product.isvat);
            post.append("data[issmoke]",this.product.issmoke);
            post.append("data[issmoke]",false);
            post.append("data[taxes]",this.product.taxes);
            post.append("data[product_type]",this.product.product_type);
            for(let i=0;i<this.smiller_items.length;i++){
                if(this.smiller_items[i].itemcode != ''){
                    post.append('data[smiller][]',this.smiller_items[i].itemcode)
                }
            }
            for(let i=0;i<this.alternatives.length;i++){
                post.append("data[alternatives]["+i+"]",this.alternatives[i].item_code);
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((response) => {
                const res = response.data;
                // document.getElementById('hidecrmprod').click();
                let message = "تمت اضافة الصنف بنجاح";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                // this.invoice.payment.cash = this.full_ftotal
                this.resetAll();
                this.$parent.getCats({id:0,type:0});
                this.$parent.getAllItems();
            });
        },
        
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        productstype: function(){
            return [
                {text: this.lang.inventory_item,value: 1},
                {text: this.lang.service_item,value: 2},
            ]
        }
    },
    created() {
        this.getCats();
        this.doconsole()
    },
}
</script>
        
<style>
label{
    color:#000 !important;
}
</style>